import React,{useEffect,useState} from "react";
import ContentWrapper from "../../../../common/components/ContentWrapper";
import HomePage from "./HomePage";
import { useTranslation } from "react-i18next";
import { homeScreenAPI } from "../../Login/redux/actions";
import { bindActionCreators } from "redux";
import { connect,useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";

const HomeComponent = ({homeScreenAPI,homeAPI}) => {
  const { t } = useTranslation("common");
  const[isSubscribed,setIsSubscribed] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    homeScreenAPI()
  }, [])

  useEffect(() => {
    if(homeAPI && homeAPI?.data?.plan){
      window.localStorage.setItem("isSubscribed",homeAPI?.data?.plan?.isSubscribed);
      setIsSubscribed(true)
      if(homeAPI?.data?.plan?.id !== null){
        navigate("/home");
        dispatch({
          type:"HOME_API_SUCCESS",
          payload:{}
        })
        // window.location.reload();
      }else{
        setIsSubscribed(false)
        navigate("/pricing");
      }
    }
  }, [homeAPI])

  return (
    <>
    {isSubscribed &&  <ContentWrapper
        title={t("MY INFO")}
        gobackUrl={"#"}
        contenview={<HomePage />}
        img={false}
      
      />}
     
    </>
  );
};

function mapStateToProps(state) {
  return {
    homeAPI: state.login.homeAPI,

  }
  
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    homeScreenAPI
  },dispatch)
  
}
export default connect(mapStateToProps,mapDispatchToProps)(HomeComponent);
