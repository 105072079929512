export function formatPhoneNumber(inputValue) {
  if (!inputValue) return ''; // Handle null or empty input

  const cleaned = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return cleaned;
};

export function formatNumber(inputValue) {
  if (typeof inputValue !== 'string') return inputValue; // Return inputValue if it's not a string
  const cleaned = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
  return cleaned;
};

export function formatCharacters(inputValue) {
  if (!inputValue) return ''; // Handle null or empty input

  const cleaned = inputValue.replace(/[^A-Za-z\d]/g, ''); // Remove non-numeric characters
  return cleaned;
};

export function formatText(inputValue) {
  if (!inputValue) return ''; // Handle null or empty input

  const cleaned = inputValue.replace(/[^A-Za-z\s]/g, ''); // Remove non-numeric characters
  return cleaned;
};
export function formatDebitCardNumber(inputValue) {
  if (!inputValue) return ''; // Handle null or empty input

  const cleaned = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
  const match = cleaned.match(/^(\d{4})(\d{4})(\d{4})(\d{4})$/);

  if (match) {
    return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
  }
  return cleaned;
};

export function formatAndMaskSSNNumber(inputValue) {
  if (!inputValue) return ''; // Handle null or empty input

  const cleaned = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
  const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);

  if (match) {
    return `XXX-XX-${match[3]}`;
  }
  return cleaned;
};

export function cleanPhoneNumber(inputValue) {
  return inputValue.replace(/\D/g, ''); // Remove non-numeric characters
};

export function isPasswordValid(password) {
  // Minimum 8 and maximum 128 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
  // var pass = value;
  // var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/;
  // var test = reg.test(pass);
  // return test;

  // Check length
  if (password.length < 8 || password.length > 128) {
    return false;
  }

  // Check character types
  const regexList = [
    /[A-Z]/, // Uppercase letters
    /[a-z]/, // Lowercase letters
    /\d/,    // Numbers
    /[!@#$%^&*()_+=\[\]{}|']/ // Non-alphanumeric characters
  ];

  // Count the number of fulfilled conditions
  let fulfilledConditions = 0;
  for (const regex of regexList) {
    if (regex.test(password)) {
      fulfilledConditions++;
    }
  }

  // Check if at least three conditions are met
  if (fulfilledConditions < 3) {
    return false;
  }

  return true;
}


export function isEmailValid(email) {
  var pass = email;
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var test = reg.test(pass);
  return test;
}

export let formatSSNNumber = (inputString) => {
  var x = inputString.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
  return !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
};

export let isNumaric = (text) => {

  let newText = '';
  let numbers = '0123456789';

  for (let val of text) {
      if (numbers.indexOf(val) > -1) {
          newText = newText + val;
      }else {
      }
  }

  return newText;
};

export let calculateAge = (dateOfBirth) => {
  const dob = new Date(dateOfBirth);
  const currentDate = new Date();

  if (dob) {
    const ageDiff = currentDate - dob;
    const ageDate = new Date(ageDiff);
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);

    return calculatedAge;
  } 
}

export function formatCreditCardNumber(inputValue) {
  if (!inputValue) return ''; 

  const cleaned = inputValue.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{4})(\d{4})(\d{4})(\d{4})$/);

  if (match) {
    return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
  }
  return cleaned;
}
