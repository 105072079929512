import { Auth } from 'aws-amplify';
import {api} from '../../../../utils/apiClient';

export const signUpAccount = (payload) => async(dispatch) => {
    try{
        dispatch({
            type: 'SIGNUP_DATA_REQUEST',
            payload: null
        });
        const signUpResponse = await Auth.signUp(payload);
        return dispatch({type: 'SIGNUP_DATA_SUCCESS', payload: signUpResponse});

    } catch(e){
        dispatch({
            type: 'SIGNUP_DATA_FAILURE',
            payload: e
        });
    }
}

export const confirmOTPAccount = (payload) => async(dispatch) => {
    try{
        dispatch({
            type: 'OTP_DATA_REQUEST',
            payload: null
        });
        const signUpResponse = await Auth.confirmSignUp(payload.username,payload.code);
        return dispatch({type: 'OTP_DATA_SUCCESS', payload: signUpResponse});

    } catch(e){
        dispatch({
            type: 'OTP_DATA_FAILURE',
            payload: e
        });
    }
}


export const  fetchRegisterPostData = (jsonData) => async (dispatch) => {
    const endPoint = 'v1/account/register';
    console.log("fetchRegisterPostData:fetchRegisterPostData", endPoint,jsonData)
    try {
        const response = await api.postNoToken(endPoint, jsonData);
        return dispatch({type: "REGISTRATION_SUCCESS", payload: response.data});
    } catch (error) {
        dispatch({type: "REGISTRATION_FAILED", payload:error});
    }
};


export const  verifyRegisteredUser = (jsonData) => async (dispatch) => {
    const endPoint = `v1/account/verify/${jsonData.email}`
    console.log("verifyRegisteredUser:verifyRegisteredUser", endPoint,jsonData)
    try {
        const response = await api.postNoToken(endPoint);
        return dispatch({type: "VERFICATION_SUCCESS", payload: response.data});
    } catch (error) {
        dispatch({type: "VERIFICATION_FAILED", payload:error});
    }
};

export const  resendVerificationCode = () => async (dispatch) => {
    const email = window.localStorage.getItem('registeremail');
     dispatch({type: "VERFICATION_RESEND_REQUEST", payload: true});
    try {
        const response = await Auth.resendSignUp(email) ;
        return dispatch({type: "VERFICATION_RESEND_SUCCESS", payload: true});
    } catch (error) {
        return dispatch({type: "VERFICATION_RESEND_FAILURE", payload: false});
    }
};

export const  clearRegistrationData = () => async (dispatch) => {
    console.log("clear registration data")
    window.localStorage.removeItem('registeremail');
     dispatch({type: "RESET_REGISTRATION", payload: true});
};